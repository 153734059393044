.App {
  text-align: center;
  z-index: 1;
  position: relative;
  height: 100vh; /* Ensure it takes the full height */
  overflow: hidden; /* Hide default scroll bars */
}

.background {
  background: radial-gradient(at top left, rgb(2, 17, 48), rgb(0, 0, 0));
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.App-header {
  display: flex;
  align-items: center;
  font-size: calc(5px + 2vmin);
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 25px;
  z-index: 1000; /* Highest z-index */
  background-color: #0f0f0f;
  border-bottom: 5px solid black;
}

.HeaderGroup {
  display: flex;
  align-items: center;
}

/* Profile Picture */
.ProfileCircle {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 2px solid black;
  margin-right: 25px;
}

.LeftSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Name, .Title {
  margin: 0;
}

.RightSection {
  display: flex;
  align-items: center;
  list-style: none;
  padding-left: 50px;
  margin: 0;
}

.RightSectionItem {
  color: white;
  text-decoration: none;
  position: relative;
  margin: 0 10px;
  cursor: pointer;
  padding: 5px;
}

.RightSectionItem::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: white;
  transition: width 0.3s ease;
}

.RightSectionItem:hover::after {
  width: 100%;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.ProjectsSubHeader {
  position: fixed; /* Change to fixed to stay on top */
  top: 60px; /* Adjust as needed to be below header */
  left: 0;
  width: 100%;
  background-color: #0f0f0f;
  padding: 10px 25px;
  box-sizing: border-box;
  border-bottom: 5px solid black;
  z-index: 999; /* Just below the header */
  transform: translateY(-100%);
  transition: transform 0.5s ease-out;
}

.ProjectsSubHeader.visible {
  transform: translateY(0);
}

.ProjectsSubHeader.hidden {
  transform: translateY(-100%);
}

.Content {
  position: absolute;
  top: 100px; /* Adjust based on header height */
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll; /* Makes it scrollable */
  padding: 10px;
  z-index: 1;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.Content::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.ScrollContainer {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  min-height: 200vh; /* Ensure it takes full height for vertical centering */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}