.AppLogo {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 2px solid black;
    margin-right: 25px;
  }
  
  .AppTitle {
    color: white;
    font-size: 2em; /* Adjust the size as needed */
    font-weight: bold;
    margin-left: 5px; /* Adjust spacing as needed */
    display: flex;
    align-items: center; /* Center vertically with the logo */
  }
  
  .Subheading {
    color: white;
    font-size: 1.5em; /* Slightly smaller than the AppTitle */
    font-weight: bold;
    text-align: center;
    margin-top: 20px; /* Adjust spacing as needed */
  }
  
  .StandardText {
    color: white;
    font-size: 1em; /* Standard text size */
    text-align: center; /* Center-align the text */
    margin-top: 10px; /* Adjust spacing as needed */
    padding: 0 20px; /* Add padding for better readability */
  }
  
  .Description {
    color: white;
    font-size: 1em;
    text-align: left;
    margin: 20px 20px 40px; /* Add more space below the description */
  }
  
  .Spacing {
    margin-top: 40px; /* Adjust spacing as needed */
  }
  